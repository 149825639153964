body#id-rlt .cls-training-products .cls-submain-tab .cls-tab-ui-label {
  transform: rotate(0deg) scaleX(-1) !important;
}

body#id-rlt .cls-training-products .cls-submain-tab .MuiTabs-indicator {
  left: unset !important;
}
body#id-rlt .cls-training-products .cls-plain-text-ui-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt .cls-create-model-training .cls-plain-text-ui-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  display: inline-block;
}
body#id-rlt .cls-create-model-training .cls-plain-value-ui-kit {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-modeltrainning-prefix .cls-showpercent-kit p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt .cls-modeltrainning-prefix .cls-td-kit-5 p {
  transform: rotate(0deg) scaleX(-1) !important;
  text-align: right !important;
}
body#id-rlt
  .cls-model-training-landingpage
  .cls-date-picker-kit
  .rdrDateRangePickerWrapper {
  transform: rotate(0deg) scaleX(-1) !important;
}
